.whyblaze {
  width: 80%;
  margin: auto;
  text-align: center;
  min-height: 100vh;
}
p {
  color: black;
  font-size: 14px;
  font-weight: 300;
  line-height: 22px;
  padding: 10px;
}
h1 {
  font-size: 36px;
  font-weight: 500;
}
.parent {
  margin: 5%;
  display: flex;
  justify-content: space-between;
}
.features {
  flex-basis: 31%;
  background: #78a5e0;
  border-radius: 10px;
  margin-bottom: 5%;
  padding: 20px 12px;
  box-sizing: border-box;
  transition: 0.5s;
}
h3 {
  font-weight: 500;
  margin: 10px 0;
}
.features:hover {
  box-shadow: 0 0 20px 0px rgba(0, 0, 0, 0.2);
}
@media (max-width: 700px) {
  .parent {
    flex-direction: column;
  }
}
