@import url('https://fonts.googleapis.com/css2?family=Noto+Sans+JP:wght@100;400;900&display=swap');
*{
	margin: 0;
	padding: 0;
	box-sizing: border-box;
	font-family: 'Poppins', sans-serif;
	font-family: 'Source Sans Pro', sans-serif;
}

.wrapper{
	background: #eeeff4;
}
.wrapper .header{
	width: 100%;
	height: 50px;
	background: #3379d4;
	color: #fff;
	text-align: center;
	display: flex;
	justify-content: center;
	align-items: center;
	font-size: 20px;
	text-transform: uppercase;
	letter-spacing: 5px;
	font-weight: 900;
}

.cards_wrap{
	padding: 20px;
	width: 100%;
	display: flex;
	flex-wrap: wrap;
}

.cards_wrap .card_item{
	padding: 15px 25px;
	width: 25%;
}

.cards_wrap .card_inner{
	background: #fff;
	border-radius: 5px;
	padding: 35px 20px;
	min-width: 225px;
	min-height: 315px;
	width: 100%;
    text-align: center;
}

.cards_wrap .card_item img{
	width: 80px;
	height: 80px;
	margin-bottom: 5px;
}

.cards_wrap .card_item .role_name{
	color: #3379d4;
	font-weight: 900;
	letter-spacing: 2px;
	text-transform: uppercase;
	font-size: 20px;
	white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.cards_wrap .card_item .real_name{
	color: #b6c0c2;
	font-size: 12px;
	font-weight: 100;
	margin: 5px 0 10px;
}

.cards_wrap .card_item .film{
	font-size: 14px;
	line-height: 24px;
	color: #7b8ca0;
}

@media screen and (max-width: 1024px){
	.cards_wrap .card_item{
		width: 33%;
	}
}

@media screen and (max-width: 768px){
	.cards_wrap .card_item{
		width: 50%;
	}
	.wrapper .header{
		font-size: 16px;
		height: 60px;
	}
}

@media screen and (max-width: 568px){
	.cards_wrap .card_item{
		width: 100%;
	}
	.wrapper .header{
		font-size: 14px;
	}
	.basic{
		color: white;
		background: white;
		padding: 12px 34px;
		text-align: center;
		font-size: 13px;
		background: transparent;
		position: relative;
		cursor: pointer;
		margin-bottom: 4px;
	}
	.basic a{
		text-decoration: none;
		color: black;
		font-size: 20px;
	}
	.basic a:hover{
		color: #3379d4;
	}
}
.text-box h1{
    font-size: 62px;
}
.text-box p{
    margin: 10px 0 40px;
    font-size: 14px;
    color: white;
}
.hero-btn{
    display: inline-block;
    text-decoration: none;
    color: #3379d4;
    border: 1px solid #3379d4;
    padding: 12px 34px;
    font-size: 13px;
    background: transparent;
    position: relative;
    cursor: pointer;
	border-radius: 20px;
}
.col-1 a{
	text-decoration: none;
}
.hero-btn:hover{
    border: 1px solid 3379d4;
    background: linear-gradient(rgba(130, 212, 233, 0.7),#ffffff);
    transition: 1s;
}
.basic{
    color: white;
	background: white;
    padding: 12px 34px;
    font-size: 13px;
    background: transparent;
    position: relative;
    cursor: pointer;
	margin-bottom: 4px;
}
.basic a{
	text-decoration: none;
	color: black;
	font-size: 20px;
}
.basic a:hover{
	color: #3379d4;
}
